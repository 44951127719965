import React from "react";
import { connect } from "react-redux";
import $ from "jquery";

import {
    Container,
    BurgerButton
} from "./style";

import Logo from "../../images/Commons/logo.svg";

class NavBar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            ScrolledAfterLimit: false,
            PrevMenu: {},
        };
    }

    componentDidMount(){
        window.addEventListener("scroll", () => {
            if(window.scrollY > window.innerHeight/2){
                this.setState({ScrolledAfterLimit: true});
            } else this.setState({ScrolledAfterLimit: false});
        });
    }

    render() {
        const ToggleMenu2 = () => {
            if(!IsMenuOpened) {
                this.setState({PrevMenu: Menu_Header_Selected});
                if(window.scrollY > 5){
                    $('html').animate({scrollTop: 0}, 500, 'swing');
                    setTimeout(() => {
                        ToggleMenu(true);
                    }, 500);
                }else ToggleMenu(true);
            }else{
                SetPageMargin(PrevMenu * -100);
                SetHeaderSelected(PrevMenu);
                ToggleMenu(false);
            }
        }

        var { 
            ScrolledAfterLimit,
            PrevMenu
        } = this.state;
        var { 
            ToggleMenu, 
            IsMenuOpened, 
            Menu_Header_Selected, 
            SetPageMargin, 
            SetHeaderSelected 
        } = this.props;
        return(
            <>
                <Container 
                ScrolledAfterLimit={ScrolledAfterLimit}
                IsMenuOpened={IsMenuOpened || ScrolledAfterLimit ? true : false}>
                    <Logo />
                    <div style={{display: "inline-flex", justifyContent: "center", alignItems: 'center', width: 'fit-content', height: 'fit-content'}}>
                        <a href="https://beta.victorgomez.dev/" style={{marginRight: '1.5rem', textDecoration: "none", color: "white", fontFamily: 'Montserrat', fontWeight: '200', fontSize: '0.85rem'}}> -{">"} Page's v2-beta</a>
                        <BurgerButton
                        aria-label="Clique para abrir ou fechar o menu de paginas"
                        IsMenuOpened={IsMenuOpened}
                        onClick={() => {
                            ToggleMenu2()
                        }}>
                            <div />
                            <div />
                            <div />
                        </BurgerButton>
                    </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => ({
    IsMenuOpened: state.windowReducer.Menu_Opened,
    Menu_Header_Selected: state.windowReducer.Menu_Header_Selected
});

const mapDispatchToProps = dispatch => ({
    ToggleMenu: (val) => dispatch({type: "TOGGLE_MENU", ToValue: val}),
    SetPageMargin: (val) => dispatch({type: "SET_PAGE_MARGIN", ToValue: val}),
    SetHeaderSelected: (val) => dispatch({type: "SET_HEADER_SELECTED", ToValue: val}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);

import styled, { css, keyframes } from "styled-components";

import {
    Theme
} from "../../globalStyles";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
`;

export const HeaderWrapper = styled.div`
    height: 100vh;
    margin-left: ${props => props.margin + "vw"};
    overflow: hidden;
    display: inline-flex;
    transition: 0.7s;
`;

export const PageHeader = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url("${props => props.backgroundLink}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    transition: .5s;
    position: relative;
    overflow: hidden;
    ::before, ::after {
        border: 3px solid transparent;
        width: 0;
        height: 0;
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
    }
    ${props => props.IsMenuOpened ? css`
        margin-top: 70px;
        transform: scale(0.65);
        border-radius: ${Theme.borderRadius};
        :hover::before, :hover::after {
            width: calc(100% - 6px);
            height: calc(100% - 6px);
        }
        :hover::before {
            border-top-color: ${Theme.accent3}; 
            border-right-color: ${Theme.accent3};
            transition:
              width 0.2s ease-out, 
              height 0.2s ease-out 0.2s; 
        }
        :hover::after {
            border-bottom-color: ${Theme.accent3}; 
            border-left-color: ${Theme.accent3};
            transition: 
              height 0.2s ease-out,
              width 0.2s ease-out 0.2s;
        }
    ` : css`
        transform: scale(1);
        :hover{
            cursor: default;
        }
    `}
    @media screen and (max-width: 720px){
        padding-right: 60px;
    }
    @media screen and (max-width: 700px){
        ${props => props.IsMenuOpened && css`
            transform: scale(0.6);
            margin-top: calc(-20vh + 140px);
        `}
    }
    @media screen and (max-width: 500px){
        ${props => props.IsMenuOpened && css`
            height: 60vh;
            transform: scale(0.9);
            margin-top: calc(-3vh + 140px);
        `}
    }
`;

export const ImageBlurHeader = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${Theme.accent2};
    opacity: 0.24;
`;
export const TitleContainerHeader = styled.div`
    width: fit-content;
    height: fit-content;
    margin-left: 6vw;
    position: absolute;
    top: auto;
    bottom: 30px;
    padding: 6vw;
    transform: translateY(-40px);
    ${props => props.displaynone && css`
        transform: translateY(0px);
        opacity: 0;
    `}
    transition: 0.2s;
    h1{
        font-family: "Montserrat";
        color: ${Theme.accent1};
        font-weight: 800;
        font-size: 34px;
    }
    h2{
        font-family: "Montserrat";
        color: ${Theme.accent1};
        font-weight: 500;
        font-size: 17.8px;
        margin-top: 8px;
        opacity: 0.5;
        max-width: 800px;
    }
    @media screen and (max-width: 745px){
        max-width: 500px;
    }
    @media screen and (max-width: 510px){
        width: calc(100vw - 30px);
        padding: 0px;
        margin-left: 15px;
        margin-right: 15px;
        h1{
            font-size: 25px;
        }
        h2{
            font-size: 19px;
        }
    }
`;

const ArrowAnimationLeft = keyframes`
    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(-10px);
    }
    100%{
        transform: translateX(0px);
    }
`;

const ArrowAnimationRight = keyframes`
    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(10px);
    }
    100%{
        transform: translateX(0px);
    }
`;

export const SideArrows = styled.button`
    background: none;
    width: fit-content;
    height: 100vh;
    padding: 20px;
    position: absolute;
    ${props => props.Left ? css`
        left: 0;
        padding-left: 90px;
        @media screen and (max-width: 720px){
            padding-left: 60px;
        }
        &:hover{
            cursor: pointer;
            svg{
                animation: 0.9s ${ArrowAnimationLeft} linear infinite;
            }
        }
    }
    ` : css`
        right: 0;
        padding-right: 90px;
        @media screen and (max-width: 720px){
            padding-right: 60px;
        }
        &:hover{
            cursor: pointer;
            svg{
                animation: 0.9s ${ArrowAnimationRight} linear infinite;
            }
        }
    `}
    svg{
        transition: 0.4s;
        margin-top: 70px;
    }
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: 0.4s;
    ${props => props.displaynone && css`
        opacity: 0;
        ${props => props.Left ? css`
            left: -10vw;
        ` : css`
            right: -10vw;
        `}
        &:hover{
            cursor: default;
        }
    `}
    border: none;
    @media screen and (max-width: 700px){
        width: 50%;
        height: 200px;
        top: auto;
    }
    @media screen and (max-height: 600px){
        height: 150px;
    }
`;

export const CustomPageLink = styled.button`
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    border: none;
    text-decoration-color: black;
    text-decoration-line: none;
    position: absolute;
    z-index: 96;
    display: flex; 
    align-items: center;
    ${props => props.displaynone && css`
        opacity: 0;
    `}
    transition: 0.4s;
    h1{
        margin-left: 140px;
        color: ${Theme.accent1};
        font-size: 45px;
        font-weight: 700;
        ::after{
            content: '';
            display: block;
            width: 40%;
            height: 6px;
            background-color: ${Theme.accent1};
            transition: 0.4s;
            margin-top: 7px;
        }
    }
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 700px){
        justify-content: center;
        align-items: center;
        h1{
            display: flex;
            flex-direction: column;
            margin: 0px;
            justify-content: center;
            align-items: center;
            ::after{
                width: 50px;
            }
        }
    }
`;

import React from "react";
import { connect } from "react-redux";

import {
    FooterContainer
} from "./style";

import {

} from "../../globalStyles";

import Logo from "../../images/Commons/logo.svg";

class Footer extends React.Component {
    render() {
        return (
            <>
                <FooterContainer
                IsMenuOpened={this.props.IsMenuOpened}>
                    <sub>
                        <title>
                            Direitos autorais de Victor Rosa Gomez
                        </title>
                        <span>
                            Esse site usa ícones do
                            <a href="https://icons8.com/"> 
                            ICONS8</a>
                        </span>
                        <ul>
                            <li>
                                <span>
                                    <a href="https://github.com/Vicg853">
                                        Meu Github</a>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <a href="https://www.instagram.com/victor_g853/">
                                        Meu Instagram</a>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <a href="https://www.linkedin.com/in/victor-rosa-gomez-15953a171/">
                                        Meu LinkedIn</a>
                                </span>
                            </li>
                        </ul>
                    </sub>
                    <sub>
                        <Logo />
                    </sub>
                </FooterContainer>
            </>
        );
    }
}

const mapStateToProps = state => ({
    IsMenuOpened: state.windowReducer.Menu_Opened
});
  
const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Footer);
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux";
import Helmet from 'react-helmet';

import Header from "./header";
import NavBar from "./navBar";
import Footer from "./footer";

import {
  GlobalStyle,
  MasterContainer,
  ContentMasterContainer,
  Theme
} from "../globalStyles";

const Layout = ({ children, IsMenuOpened, Page_Title, Menu_Header_Selected }) => {
  return (
    <>
        <GlobalStyle 
        IsMenuOpened={IsMenuOpened}/>
        <Helmet>
            <html lang="pt-BR" />
            <meta charset="UTF-8" />
            <meta name="description" content="Portifolio de Victor Rosa Gomez" />
            <meta name="keywords" content="Victor Rosa Gomez, Victor Gomez, Desenvolvedor, Developer, Fullstack, Fullstack Developer" />
            <meta name="author" content="Victor Rosa Gomez" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link href="https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap" rel="stylesheet" />
            <title>{IsMenuOpened ? "Menu" : Page_Title} | V.G.</title>
            <noscript>Você deve habilitar o javascript para usar esta página!</noscript>
        </Helmet>
        <MasterContainer
        IsMenuOpened={IsMenuOpened}
        display={Menu_Header_Selected !== -1 ? true : false}>
          <NavBar />
          <Header 
          backgroundColor={Theme.accent2} />
          <ContentMasterContainer
          IsMenuOpened={IsMenuOpened}>
            {children}
          </ContentMasterContainer>
          <Footer />
        </MasterContainer>
        {Menu_Header_Selected === -1 && children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = state => ({
  IsMenuOpened: state.windowReducer.Menu_Opened,
  Page_Title: state.windowReducer.Page_Title,
  Menu_Header_Selected: state.windowReducer.Menu_Header_Selected
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
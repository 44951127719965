import React from "react"
import { Provider } from "react-redux"

import createStore from "./store";
import Layout from "./components/layout";

export default ({ element }) => {
  const store = createStore()
  return (
    <Provider store={store}>
      <Layout>
        {element}
      </Layout>
    </Provider>
  );
}
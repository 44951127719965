import styled, {css} from "styled-components";

import {
    Theme
} from "../../globalStyles";

export const FooterContainer = styled.div`
    margin-top: 30px;
    width: 100vw;
    min-height: 250px;
    ${props => props.IsMenuOpened ? css`
        display: none;
    `: css`
        display: inline-flex;
    `}
    overflow: hidden;
    justify-content: space-between;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: ${Theme.accent2};
    sub{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    title{
        display: block;
        font-size: 20px;
        font-weight: bold;
        color: ${Theme.accent1};
        margin-bottom: 17px;
    }
    li{
        color: ${Theme.accent1};
    }
    span{
        display: inline-flex;
        width: fit-content;
        font-size: 16.5px;
        font-weight: 500;
        color: ${Theme.accent1};
        opacity: 0.6;
        margin-bottom: 10px;
        transition: 0.4s;
        a{
            width: fit-content;
            text-decoration-color: ${Theme.accent1};
            text-decoration-line: none;
            color: ${Theme.accent1};
            display: block;
            overflow: hidden;
            align-items: center;
            ::after{
                display: block;
                content: "";
                width: 0px;
                height: 3px;
                background-color: ${Theme.accent1};
                margin-top: -3px;
                transition: 0.3s;
            }
            :hover{
                ::after{
                    width: 100%;
                }
            }
        }
    }
    svg{
        width: 210px;
        height: 75px;
        justify-self: center;
        align-self: center;
    }
    @media screen and (max-width: 700px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        padding: 20px;
        sub{
            margin-bottom: 10px;
            width: 99%;
        }
    }
`;

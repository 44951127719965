import React from "react";
import { connect } from "react-redux";
import { globalHistory, navigate } from "@reach/router";

import {
    Container,
    PageHeader,
    ImageBlurHeader,
    TitleContainerHeader,
    HeaderWrapper,
    SideArrows,
    CustomPageLink
} from "./style";

import Image0 from "../../images/Header/homeBackground.jpg";
import Image1 from "../../images/Header/projectsHeader.jpg";
import Image2 from "../../images/Header/contactHeader.jpg";

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            alreadyRunFunc: false
        }
    }
    render(){
        var { 
            IsMenuOpened, 
            ToggleMenu,
            Page_Margin, 
            SetPageMargin, 
            SetHeaderSelected,
            SetPageTitle,
            Menu_Header_Selected 
        } = this.props;

        const URLs = [
            {path: "/", id: 0, title: "Home"},
            {path: "/projects", id: 1, title: "Projects"},
            {path: "/contact" ,id: 2, title: "Contact"},
        ]
        
        //Function that changes header position
        function Slider(location){
            var PageSet = URLs.some((content) => {
                if(location === content.path || location === content.path + "/"){
                    SetPageMargin(content.id * -100);
                    SetHeaderSelected(content.id);
                    SetPageTitle(content.title);
                    return true;
                }
            });
            if(PageSet) return;
            if(!PageSet){
                SetHeaderSelected(-1);
                SetPageTitle("404");
            }
        }

        //First time header set for the first component mount
        if(!this.state.alreadyRunFunc){
            var locationInitial = globalHistory.location;
            Slider(locationInitial.pathname);
            this.setState({alreadyRunFunc: true});
        }

        return(
            <>
                <Container>
                    <HeaderWrapper
                    margin={Page_Margin}>
                        <PageHeader
                        IsMenuOpened={IsMenuOpened}
                        backgroundLink={Image0} 
                        className="swiper-slide">
                            {IsMenuOpened && (
                            <CustomPageLink 
                                aria-label="Clique para entrar na pagina principal"
                                displaynone={!IsMenuOpened}
                                onClick={() => {
                                    ToggleMenu(false)
                                    setTimeout(() => {
                                        navigate(`/`);
                                    }, 550);
                                }}>
                                    <h1>Home</h1>
                                </CustomPageLink> )
                            }
                            <ImageBlurHeader />
                            <TitleContainerHeader
                            displaynone={IsMenuOpened}>
                                <h1>
                                    Olá! Sou Victor Gomez
                                </h1>
                                <h2>
                                    Olá sou um iniciante em ciência da computação, fullstack development 
                                </h2>
                            </TitleContainerHeader>
                        </PageHeader>
                        <PageHeader
                        IsMenuOpened={IsMenuOpened}
                        backgroundLink={Image1}
                        className="swiper-slide">
                            {IsMenuOpened && ( 
                                <CustomPageLink 
                                aria-label="Clique para entrar na pagina de projetos"
                                displaynone={!IsMenuOpened}
                                onClick={() => {
                                    ToggleMenu(false)
                                    setTimeout(() => {
                                        navigate(`/projects`);
                                    }, 550);
                                }}>
                                    <h1>Projetos</h1>
                                </CustomPageLink> )
                            }
                            <ImageBlurHeader />
                            <TitleContainerHeader
                            displaynone={IsMenuOpened}>
                                <h1>
                                    Projetos
                                </h1>
                                <h2>
                                Veja aqui os projetos que já realizei além desse site
                                </h2>
                            </TitleContainerHeader>
                        </PageHeader>
                        <PageHeader
                        IsMenuOpened={IsMenuOpened}
                        backgroundLink={Image2}
                        className="swiper-slide">
                            {IsMenuOpened && (
                                <CustomPageLink
                                aria-label="Clique para entrar na pagina de contato"
                                displaynone={!IsMenuOpened}
                                onClick={() => {
                                    ToggleMenu(false)
                                    setTimeout(() => {
                                        navigate(`/contact`);
                                    }, 550);
                                }}>
                                    <h1>Contato</h1>
                                </CustomPageLink> )
                            }
                            <ImageBlurHeader />
                            <TitleContainerHeader
                            displaynone={IsMenuOpened}>
                                <h1>
                                    Contato
                                </h1>
                                <h2>
                                    Entre em contato comigo, ou siga me nas redes sociais
                                </h2>
                            </TitleContainerHeader>
                        </PageHeader>
                    </HeaderWrapper>
                    <SideArrows Left 
                    aria-label="Mudar de pagina no menu de paginas - ir para a esquerda"
                    onClick={() => {
                        if(Menu_Header_Selected > 0 && IsMenuOpened){
                            var location = URLs[Menu_Header_Selected - 1].path;
                            Slider(location, "right");
                        }
                    }}
                    displaynone={Menu_Header_Selected === 0 || !IsMenuOpened}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="50" height="50"
                        viewBox="0 0 172 172"
                        style={{background: "transparent"}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#FFBA48"><path d="M40.02135,51.54401c-1.48951,0.04438 -2.90324,0.6669 -3.94167,1.73568l-28.16276,28.16276c-1.41918,1.08154 -2.25398,2.76211 -2.25837,4.54643c-0.00439,1.78431 0.82213,3.46898 2.23597,4.55748c0.01117,0.0075 0.02237,0.01497 0.03359,0.02239l28.15156,28.15156c1.43802,1.49776 3.57339,2.1011 5.58258,1.57732c2.00919,-0.52378 3.57824,-2.09283 4.10202,-4.10202c0.52378,-2.00919 -0.07955,-4.14456 -1.57731,-5.58258l-18.87969,-18.87969h135.22604c2.06765,0.02924 3.99087,-1.05709 5.03322,-2.843c1.04236,-1.78592 1.04236,-3.99474 0,-5.78066c-1.04236,-1.78592 -2.96558,-2.87225 -5.03322,-2.843h-135.22604l18.87969,-18.87969c1.69569,-1.64828 2.20555,-4.16851 1.28389,-6.3463c-0.92166,-2.17779 -3.08576,-3.56638 -5.44951,-3.49667z"></path></g></g></svg>
                    </SideArrows>
                    <SideArrows 
                    aria-label="Mudar de pagina no menu de paginas - ir para a direita"
                    onClick={() => {
                        if(Menu_Header_Selected < URLs.length - 1 && IsMenuOpened){
                            var location = URLs[Menu_Header_Selected + 1].path;
                            Slider(location, "right");
                        }
                    }} 
                    displaynone={Menu_Header_Selected === URLs.length - 1 || !IsMenuOpened} >
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="50" height="50"
                        viewBox="0 0 172 172"
                        style={{background: "transparent"}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffba48"><path d="M131.81067,51.54401c-2.33302,0.00061 -4.43306,1.41473 -5.31096,3.57628c-0.8779,2.16155 -0.3586,4.6395 1.31331,6.26668l18.87969,18.87969h-135.22604c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h135.22604l-18.87969,18.87969c-1.49778,1.43802 -2.10113,3.5734 -1.57735,5.5826c0.52378,2.0092 2.09284,3.57826 4.10204,4.10204c2.0092,0.52378 4.14458,-0.07957 5.5826,-1.57735l28.16276,-28.16276c1.41918,-1.08154 2.25398,-2.76211 2.25837,-4.54643c0.00439,-1.78431 -0.82213,-3.46898 -2.23597,-4.55748c-0.01117,-0.0075 -0.02237,-0.01497 -0.03359,-0.02239l-28.15157,-28.15156c-1.07942,-1.10959 -2.56163,-1.73559 -4.10964,-1.73568z"></path></g></g></svg>
                    </SideArrows>
                </Container>
            </>
        );
    };
}

const mapStateToProps = state => ({
    IsMenuOpened: state.windowReducer.Menu_Opened,
    Page_Margin: state.windowReducer.Page_Margin,
    Menu_Header_Selected: state.windowReducer.Menu_Header_Selected
});
  
const mapDispatchToProps = dispatch => ({
    ToggleMenu: (val) => dispatch({type: "TOGGLE_MENU", ToValue: val}),
    SetPageMargin: (val) => dispatch({type: "SET_PAGE_MARGIN", ToValue: val}),
    SetHeaderSelected: (val) => dispatch({type: "SET_HEADER_SELECTED", ToValue: val}),
    SetPageTitle: (val) => dispatch({type: "SET_PAGE_TITLE", ToValue: val}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);
import styled, { createGlobalStyle, css } from "styled-components";

export const Theme = {
    background: "#0b0a0a",
    accent1: "#FFFFFF",
    accent2: "#1b1b1b",
    accent3: "#FFBA48",
    red: "#E92626",
    green: "#1FF01B",
    borderRadius: "5px",
}

export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        -webkit-font-smoothing: antialiased !important;
        text-rendering: optimizeLegibility !important;
        text-decoration-line: none;
        outline: none;
        font-family: "Montserrat";
        box-sizing: border-box; 
        letter-spacing: 0.7px;
        line-height: calc(100% + 6px);
        background-repeat: no-repeat;
    }
    ::-webkit-scrollbar{
        width: 14px;
        background-color: transparent;
        color: transparent;
    }
    ::-webkit-scrollbar-thumb{
        background-color: #FFFFFF;
        color: #FFFFFF;
        width: 14px;
    }
    ::-webkit-scrollbar-track{
        background-color: #19191E;
        color: #19191E;
    }
    #nprogress .bar {
        background: ${Theme.accent1} !important;
        height: 3px !important;
    }
    #nprogress .spinner {
        top: 20px !important;
        right: 50% !important;
    }
    #nprogress .spinner-icon {
        border-top-color: ${Theme.accent1} !important;
        border-left-color: ${Theme.accent1} !important;
    }
    input{
        -webkit-appearance: none;
    }
    html{
        width: calc(100vw - 14px);
        height: 100vh;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        outline: none;
        -webkit-appearance: none;
        ${props => props.IsMenuOpened ? css`
            overflow-y: hidden;
        ` : css`
            overflow-y: scroll;
        `}
    }
    body{
        width: calc(100vw);
        overflow: hidden;
    }
    li{
        margin-left: 30px;
    }
    #___gatsby{
        width: 100vw;
        overflow-x: hidden;
    }
`;

export const MasterContainer = styled.div`
    width: 100vw;
    height: fit-content;
    background: ${Theme.background};
    overflow: hidden;
    display: ${props => props.display ? "block" : "none"};
`;

export const ContentMasterContainer = styled.div`
    width: 100vw;
    height: fit-content;
    overflow: hidden;
    ${props => props.IsMenuOpened && css`
        display: none;
    `}
`;

export const RootContainerStyle = styled.div`
    width: calc(100vw - 140px);
    max-width: 1150px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 10px;
    @media screen and (max-width: 1000px){
        width: calc(100vw - 70px);
    }
`;

export const MainContainerStyle = styled(RootContainerStyle)`
    height: 380px;
    div{
        width: ${props => props.ImgWidth ? props.ImgWidth : "50%"};
        height: 380px;
        background: url("${props => props.BackgroundImage}");
        background-position: center center;
        background-size: cover;
        border-radius: ${Theme.borderRadius};
    }
    article{
        width: ${props => props.ArticleWidth ? props.ArticleWidth : "50%"};
        height: auto;
        display: block;
        font-size: 15.3px;
        font-weight: 400;
        color: rgba(255,255,255, 0.85);
        margin-left: 2.7%;
        margin-right: 15px;
    }
    title{
        display: block;
        font-size: 23px;
        font-weight: 700;
        color: ${Theme.accent1};
        ::after{
            display: block;
            content: "";
            width: 70px;
            height: 3px;
            background-color: ${Theme.accent3};
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }
    @media screen and (max-width: 740px){
        height: auto;
        display: flex;
        flex-direction: column;
        div, article{
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
        }
        div{
            height: 200px;
            order: 1;
        }
        article{
            margin-top: 40px;
            align-self: flex-end;
            order: 2;
        }
    }
`;

export const SocialMediaIconContainer = styled(RootContainerStyle)`
    flex-direction: column;
    title{
        display: block;
        font-size: 22px;
        font-weight: 700;
        color: ${Theme.accent1};
    }
    sub{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-top: 20px;
    }
`;

export const SocialMediaIcon = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    text-decoration-line: none;
    width: 50px;
    height: 50px;   
    background-color: ${Theme.accent2};
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 15px;
    transition: 0.2s;
    svg{
        display: block;
        content: "";
        width: 30px;
        height: 30px;
        transition: 0.2s;
    }
    :hover{
        cursor: pointer;
        background-color: ${Theme.background};
        svg{
            transform: scale(0.85);
        }
    }
`;
const initialState = {
    Menu_Opened: false,
    Menu_Header_Selected: 0,
    Page_Margin: 0,
    Page_Title: ""
};

export const windowReducer = (state = initialState, action) => {
    switch(action.type){
        case "SET_PAGE_MARGIN": return {...state, Page_Margin: action.ToValue};
        case "SET_HEADER_SELECTED": return {...state, Menu_Header_Selected: action.ToValue};
        case "SET_PAGE_TITLE": return {...state, Page_Title: action.ToValue};
        case "TOGGLE_MENU": return {...state, Menu_Opened: action.ToValue};
        default: return state;
    }
};
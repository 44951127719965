import styled, { css } from "styled-components";

import {
    Theme
} from "../../globalStyles";

export const Container = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    ${props => props.ScrolledAfterLimit || props.IsMenuOpened ? css`
        background-color: ${Theme.background};
        height: 100px;
        padding-left: 6%;
        padding-right: 6%;
    `: css`
        background-color: transparent;
        height: 160px;
        padding-left: 11%;
        padding-right: 11%;
    `}
    z-index: 2;
    transition: 0.6s;
    svg{
        width: 210px;
        height: 75px;
    }
    a{
        padding: 0.1rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        border-radius: 0.4rem;
        transition: 0.3s;
        :hover{
            background: rgba(255,255,255,0.2);
        }
    }
`;

export const BurgerButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1;
    div {
        width: 30px;
        height: 2.5px;
        background: ${Theme.accent3};
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        :first-child {
            ${(props) => props.IsMenuOpened && css`
                transform: rotate(45deg);
            `}
        }
        :nth-child(2) {
          opacity: ${(props) => props.IsMenuOpened ? '0' : '1'};
          transform: ${(props) => props.IsMenuOpened ? 'translateX(40px)' : 'translateX(0)'};
        }
      
        :nth-child(3) {
            ${(props) => props.IsMenuOpened && css`
                transform: rotate(-45deg);
            `}
        }
    }   
`;